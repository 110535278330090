import React from "react";
import { ButtonIconProps } from "./button-icon.types";
import { buttonIcon } from "./button-icon.styles";

export const ButtonIcon = ({
  icon,
  size = "small",
  type = "button",
  ...buttonAttributes
}: ButtonIconProps) => {
  return (
    <button type={type} className={buttonIcon({ size })} {...buttonAttributes}>
      <i className={icon} />
    </button>
  );
};
