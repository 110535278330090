"use client";

import React, { KeyboardEventHandler, useRef } from "react";
import { cn } from "~/utils/cn";
import { Input, InputAdornmentKind } from "../default";
import { InputSearchProps } from "./input-search.types";
import { iconButtonClassName } from "../../icon/icon.styles";

export const InputSearch: React.FC<InputSearchProps> = ({
  onChangeSearch,
  search,
  hideSearchIcon = false,
  onPressEnter,
  icon,
  preventDefaultOnEnter,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const clearSearch = () => {
    const input = inputRef.current;

    if (input) {
      input.value = "";
    }

    onChangeSearch("");
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === "Enter") {
      if (preventDefaultOnEnter) event.preventDefault();
      if (onPressEnter) onPressEnter();
    }
  };

  return (
    <Input
      ref={inputRef}
      type="search"
      aria-label="Search input"
      onChange={event => onChangeSearch(event.target.value)}
      value={search}
      onKeyDown={onKeyDown}
      startAdornment={{
        icon: icon ?? "fa-regular fa-magnifying-glass",
        kind: InputAdornmentKind.normal,
      }}
      endAdornment={{
        icon: "fa-regular fa-xmark",
        "aria-label": "Clear search",
        onClick: () => clearSearch(),
        className: cn(
          iconButtonClassName,
          "flex text-icon-neutral-20 opacity-100 transition-opacity duration-150",
          {
            "pointer-events-none opacity-0": !search,
          },
        ),
      }}
      {...props}
    />
  );
};
