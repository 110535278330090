import { tv } from "tailwind-variants";

export const buttonIcon = tv({
  base: "rounded text-icon-neutral-20 hover:enabled:bg-neutral-10 active:enabled:bg-neutral-30 disabled:text-neutral-disabled",
  variants: {
    size: {
      small: "h-6 w-6",
      medium: "h-8 w-8",
    },
  },
});
