import { tv } from "tailwind-variants";
import { CaptionColor, CaptionSize, CaptionType } from "./caption.types";

export const captionClassName = tv({
  base: "text-xs tracking-tight",
  variants: {
    type: {
      [CaptionType.emphasys]: "font-medium",
      [CaptionType.basic]: "",
    },
    color: {
      [CaptionColor.primary]: "text-neutral-primary",
      [CaptionColor.secondary]: "text-neutral-secondary",
    },
    underline: {
      true: "underline",
      false: "",
    },
    italic: {
      true: "italic",
      false: "",
    },
    center: { true: "text-center" },
    size: { [CaptionSize.large]: "text-sm leading-6" },
  },
  defaultVariants: {
    type: CaptionType.basic,
    color: CaptionColor.primary,
    underline: false,
    italic: false,
  },
});
