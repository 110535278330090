import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../dialog";
import { Body, BodySize, Title, TitleSize } from "../../typography";
import { Button, ButtonKind, ButtonWidth } from "../../button";
import {
  ConfirmationDialogKind,
  ConfirmationDialogProps,
} from "./confirmation-dialog.types";
import { iconStyles } from "./confirmation-dialog.styles";
import { DialogDescription } from "@radix-ui/react-dialog";
import { cn } from "~/utils/cn";

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  onOpenChange,
  icon,
  hasCancelButton = true,
  disabled,
  description,
  loading,
  kind = ConfirmationDialogKind.information,
  className,
  onInteractOutside,
  onEscapeKeyDown,
  onPointerDown,
  ...props
}) => {
  if (!isOpen) {
    return null;
  }
  const labelConfirm = props.labelConfirm ?? "Confirm";
  const labelCancel = props.labelCancel ?? "Cancel";

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn(
          "flex w-[375px] flex-col gap-0 rounded-xl p-6",
          className,
        )}
        onEscapeKeyDown={onEscapeKeyDown}
        onPointerDown={onPointerDown}
        onInteractOutside={onInteractOutside}
      >
        <DialogDescription className="hidden">
          {description ?? title}
        </DialogDescription>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <i className={iconStyles({ kind })} />

            <DialogTitle>
              <Title size={TitleSize.extraSmall} asChild>
                <span>{title}</span>
              </Title>
            </DialogTitle>
          </div>

          <Body size={BodySize.small}>{message}</Body>
        </div>
        <div className="mt-6 flex w-full gap-2">
          {hasCancelButton && (
            <DialogTrigger asChild>
              <Button
                width={ButtonWidth.full}
                kind={ButtonKind.secondary}
                onClick={onCancel}
                aria-label={labelCancel}
                disabled={loading}
              >
                {labelCancel}
              </Button>
            </DialogTrigger>
          )}

          <Button
            kind={
              kind === ConfirmationDialogKind.information
                ? ButtonKind.primary
                : ButtonKind.critical
            }
            width={ButtonWidth.full}
            disabled={disabled ?? loading}
            loading={loading}
            onClick={onConfirm}
            aria-label={labelConfirm}
          >
            {labelConfirm}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
