import { MaskTypeEnum } from "./input-mask.constants";

export const maskJustNumber = (value: string) => {
  const formattedDate = value.replace(/\D/g, "");

  return formattedDate;
};

export const maskHourMinute = (value: string) => {
  value = value.replace(/\D/g, "");

  if (value.length > 4) {
    value = value.slice(0, 4);
  }

  if (value.length > 2) {
    return value.slice(0, 2) + ":" + value.slice(2);
  } else {
    return value;
  }
};

export const maxInputLength = (value: string, maxLength: number) => {
  if (value.length > maxLength) {
    value = value.slice(0, maxLength);
  }

  return value;
};

export const maskMappings = {
  [MaskTypeEnum.JUST_NUMBER]: maskJustNumber,
  [MaskTypeEnum.HOUR_MINUTE]: maskHourMinute,
};
