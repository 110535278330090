export enum TitleSize {
  extraSmall = "extra-small",
  small = "small",
  medium = "medium",
  large = "large",
  extraLarge = "extra-large",
}

export enum TitleType {
  emphasys = "emphasys",
  basic = "basic",
  muted = "muted",
  light = "light",
};

export interface TitleProps {
  size?: TitleSize;
  type?: TitleType;
  asChild?: boolean;
  children: React.ReactNode;
  center?: boolean;
  className?: string;
}
