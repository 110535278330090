"use client";

import React, { forwardRef, useState } from "react";
import { Input, InputProps } from "..";

type PasswordInputProps = InputProps;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref) => {
    const [isTypePassword, setIsTypePassword] = useState(true);

    return (
      <Input
        {...props}
        ref={ref}
        type={isTypePassword ? "password" : "text"}
        endAdornment={{
          icon: isTypePassword
            ? "fa-regular fa-eye text-icon-neutral-20"
            : "fa-regular fa-eye-slash text-icon-neutral-20",
          onClick: () => setIsTypePassword(!isTypePassword),
          "aria-label": "toggle-password",
        }}
      />
    );
  },
);
