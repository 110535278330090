"use client";

import * as React from "react";

import { cn } from "~/utils/cn";

import { inputMaskClassname } from "./input-mask.styles";
import { InputMaskProps } from "./input-mask.types";
import { maskMappings } from "./input-mask.utils";

const InputMask = React.forwardRef<HTMLInputElement, InputMaskProps>(
  ({ className, type = "text", mask, maxLength, ...props }, ref) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = event.target.value;

      const maskFunction = maskMappings[mask];

      if (maskFunction) {
        inputValue = maskFunction(inputValue);
      }

      event.target.value = inputValue;

      if (props.onChange) {
        props.onChange(event);
      }
    };

    return (
      <input
        {...props}
        type={type}
        className={cn(inputMaskClassname(), className)}
        ref={ref}
        maxLength={maxLength}
        onChange={handleInputChange}
      />
    );
  },
);

InputMask.displayName = "InputMask";

export { InputMask };
